import React from 'react';
import './ServicePage.css';
import { FaCheckCircle, FaQuoteLeft } from 'react-icons/fa'; // Import icons
import CallToAction from '../sections/CallToAction';
import Testimonials from '../sections/Testimonials';

const MobileAppWebServices = () => {
  return (
    <div className="service-page">
      <h1>Mobile App/Web Services</h1>
      <img src="/images/mobile_app_innovation.webp" alt="Mobile App/Web Services" />

      <section className="service-intro">
        <h2>Introduction</h2>
        <p>At Dahlhauser Solutions, we provide top-tier Mobile App and Web Services designed to help businesses thrive in the digital age. Our team of skilled developers and designers deliver custom solutions tailored to your specific needs, ensuring a seamless user experience and robust functionality.</p>
      </section>

      <section className="service-details">
        <h2>Our Services</h2>
        <ul>
          <li><FaCheckCircle /> Custom mobile app development (iOS, Android)</li>
          <li><FaCheckCircle /> App maintenance and updates</li>
          <li><FaCheckCircle /> App store optimization and deployment</li>
          <li><FaCheckCircle /> Integration with existing business systems</li>
          <li><FaCheckCircle /> User experience and interface design</li>
          <li><FaCheckCircle /> Website design and development tailored to business needs</li>
          <li><FaCheckCircle /> Website hosting and domain management</li>
          <li><FaCheckCircle /> Ongoing website maintenance and updates</li>
          <li><FaCheckCircle /> SEO optimization and analytics setup</li>
          <li><FaCheckCircle /> Content management system (CMS) implementation</li>
        </ul>
      </section>

      <section className="service-benefits">
        <h2>Benefits of Our Mobile App/Web Services</h2>
        <ul>
          <li><FaCheckCircle /> <strong>Custom Solutions:</strong> Get tailored mobile and web solutions that meet your business requirements.</li>
          <li><FaCheckCircle /> <strong>Enhanced User Experience:</strong> Deliver a seamless and intuitive user experience with professional design and development.</li>
          <li><FaCheckCircle /> <strong>Robust Functionality:</strong> Ensure your applications and websites are feature-rich and perform reliably.</li>
          <li><FaCheckCircle /> <strong>SEO Optimization:</strong> Improve your online visibility with expert SEO services and analytics setup.</li>
          <li><FaCheckCircle /> <strong>Ongoing Support:</strong> Benefit from continuous maintenance and updates to keep your apps and websites running smoothly.</li>
        </ul>
      </section>

      <Testimonials newStyle={true} />

      <section className="service-cta">
        <CallToAction />
      </section>
    </div>
  );
};

export default MobileAppWebServices;
