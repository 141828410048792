import React from 'react';
import { Link } from 'react-router-dom';
import "./ServicesTeaser.css"

const services = [
  {
    id: 'azure-architecture',
    title: 'Azure Architecture and Management',
    description: 'Tailored Azure infrastructure design to maximize efficiency and reduce costs.',
    iconPath: '/icons/azure-icon.svg' // Replace with the path to your actual icon
  },
  {
    id: 'o365-management',
    title: 'M365 Management',
    description: 'Comprehensive setup and optimization of collaboration tools, ensuring seamless communication across your team.',
    iconPath: '/icons/office-icon.svg' // Replace with the path to your actual icon
  },
  {
    id: 'security-management',
    title: 'Security Management',
    description: 'Proactive measures to safeguard your data and enhance your Secure Score.',
    iconPath: '/icons/security-icon.svg' // Replace with the path to your actual icon
  },
  {
    id: 'server-desktop-management',
    title: 'Desktop & Server Management',
    description: 'Ensuring the reliability and performance of your essential IT assets.',
    iconPath: '/icons/desktop-server-icon.svg' // Replace with the path to your actual icon
  },
  {
    id: 'web-mobile-app-services',
    title: 'Website & Mobile App Services',
    description: 'Custom digital solutions to capture your brand’s essence and engage your audience.',
    iconPath: '/icons/web-mobile-icon.svg' // Replace with the path to your actual icon
  },
  {
    id: 'bundled-managed-services',
    title: 'Bundled Managed Services',
    description: 'A complete IT support package designed for the unique needs of small businesses.',
    iconPath: '/icons/bundled-services-icon.svg' // Replace with the path to your actual icon
  },
  {
    id: 'connectivity-services',
    title: 'Low Voltage & Cabling Solutions',
    description: 'Expert installation and management of CAT6 cabling systems for superior connectivity and network performance.',
    iconPath: '/icons/cabling-icon.svg' // Replace with the path to your actual icon
  },
  {
    id: 'wifi-av-integration',
    title: 'WiFi & Audio-Visual Integration',
    description: 'Advanced WiFi network solutions and state-of-the-art audio-visual setups tailored to enhance business environments.',
    iconPath: '/icons/wifi-icon.svg' // Replace with the path to your actual icon
  }
];

const ServicesTeaser = () => {
  return (
    <section className="py-5">
      <div className="container">
        <h2 className="text-center mb-5">Services Overview</h2>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {services.map((service) => (
            <ServiceCard key={service.id} service={service} />
          ))}
        </div>
      </div>
    </section>
  );
};

const ServiceCard = ({ service }) => {
  return (
    <Link to={`/services/${service.id}`} className="col text-center p-4 transition-all rounded-lg service-card" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
      <img
        src={service.iconPath}
        alt={service.title}
        className="mb-4"
        style={{ height: '48px', width: '48px' }}
      />
      <h3 className="h5 font-weight-medium">{service.title}</h3>
      <p className="text-muted service-description">
        {service.description}
      </p>
    </Link>
  );
};

export default ServicesTeaser;
