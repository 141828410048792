import React from 'react';
import './ServicePage.css';
import { FaCheckCircle, FaQuoteLeft } from 'react-icons/fa'; // Import icons
import CallToAction from '../sections/CallToAction';
import Testimonials from '../sections/Testimonials';

const ServerDesktopManagement = () => {
  return (
    <div className="service-page">
      <h1>Server/Desktop Monitoring and Management</h1>
      <img src="/images/desktop_server_management.webp" alt="Server/Desktop Monitoring and Management" />

      <section className="service-intro">
        <h2>Introduction</h2>
        <p>At Dahlhauser Solutions, our Server/Desktop Monitoring and Management services ensure your IT infrastructure runs smoothly and efficiently. We provide comprehensive support, from software installation and updates to remote troubleshooting and performance optimization.</p>
      </section>

      <section className="service-details">
        <h2>Our Services</h2>
        <ul>
          <li><FaCheckCircle /> Software installation, updates, and patch management</li>
          <li><FaCheckCircle /> Setup and Configuration</li>
          <li><FaCheckCircle /> Antivirus and malware protection</li>
          <li><FaCheckCircle /> Remote troubleshooting and support</li>
          <li><FaCheckCircle /> Hardware and software inventory management</li>
          <li><FaCheckCircle /> Performance monitoring and optimization</li>
          <li><FaCheckCircle /> Regular maintenance and updates</li>
          <li><FaCheckCircle /> User account and access management</li>
        </ul>
      </section>

      <section className="service-benefits">
        <h2>Benefits of Our Server/Desktop Monitoring and Management Services</h2>
        <ul>
          <li><FaCheckCircle /> <strong>Reliable Performance:</strong> Ensure your systems run smoothly with regular maintenance and performance optimization.</li>
          <li><FaCheckCircle /> <strong>Enhanced Security:</strong> Protect your devices with antivirus and malware protection.</li>
          <li><FaCheckCircle /> <strong>Proactive Support:</strong> Receive timely updates and patch management to avoid potential issues.</li>
          <li><FaCheckCircle /> <strong>Comprehensive Monitoring:</strong> Keep track of your IT assets with hardware and software inventory management.</li>
          <li><FaCheckCircle /> <strong>Remote Troubleshooting:</strong> Resolve issues quickly with our remote support services.</li>
        </ul>
      </section>

      <Testimonials newStyle={true} />

      <section className="service-cta">
        <CallToAction />
      </section>
    </div>
  );
};

export default ServerDesktopManagement;
