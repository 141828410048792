import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { app } from '../firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CircularProgress } from '@mui/material';

const CallToAction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [captchaValidated, setCaptchaValidated] = useState(false);
  // Assuming `app` is your Firebase app instance
  const functions = getFunctions(app);
  const sendEmailFunction = httpsCallable(functions, 'sendEmail');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptcha = (value) => {
    setCaptchaValidated(!!value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when form submission starts
    if (captchaValidated) {
      try {
        await sendEmailFunction({ name: formData.name, email: formData.email, message: formData.message });
        alert('Form submitted successfully!');
      } catch (error) {
        console.error('Submission error:', error);
        alert('Submission failed. Please try again later.');
      }
      setIsLoading(false); // Set loading to false when form submission is complete
    } else {
      alert('Please complete the CAPTCHA to proceed.');
      setIsLoading(false); // Also set loading to false if captcha is not validated
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <CircularProgress />
        </div>
      ) : (
        <div className="container py-5">
          <h2 className="text-center mb-4">Ready to take your business to the next level with Dahlhauser Solutions?</h2>
          <p className="text-center mb-5">Contact us today for a personalized consultation, and let's build the future of your business together.</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <textarea
                name="message"
                placeholder="Your Message"
                onChange={handleChange}
                required
                className="form-control"
                rows="4"
              ></textarea>
            </div>
            <div className="d-flex justify-content-center mb-3">
              <ReCAPTCHA
                sitekey="6LfDxn8pAAAAADhRbd648lIZdqG3s6zzR0b2LCCy" // Replace with your actual site key
                onChange={handleCaptcha}
              />
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
              >
                Get Started
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default CallToAction;
