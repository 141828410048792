import React from 'react';
import aboutImage from '../assets/overview-removebg3.png';
import { motion } from 'framer-motion';

const AboutOverview = () => {
  return (
    <section className="py-5">
      <div className="container px-4">
        <div className="row justify-content-center align-items-center">
          <motion.div
            className="col-md-6 mb-4 mb-md-0"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-center text-md-start fw-semibold mb-4">Transforming Charlotte Businesses</h2>
            <p className="fs-5 mb-3">
              We specialize in harnessing the power of IT services and AI to transform small to medium-sized businesses in the Charlotte Metro area. Our mission is to make cutting-edge technology accessible, helping companies optimize their operations, enhance security, and innovate continuously.
            </p>
            <p className="fs-5 mb-3">
              We offer a complete suite of services including Azure Architecture, O365 Management, and AI-driven solutions alongside our expertise in low voltage solutions like CAT6 cabling, advanced WiFi setups, and customized AV services. These integrated services ensure that your infrastructure supports and enhances your IT environment.
            </p>
            <p className="fs-5">
              As your IT Support growth partner, we provide not just services, but comprehensive solutions tailored to meet the unique needs of your business.
            </p>
          </motion.div>
          <motion.div
            className="col-md-6"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={aboutImage} alt="Charlotte IT Support" className="img-fluid mx-auto d-block" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AboutOverview;
