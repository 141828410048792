import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center py-2 fixed-bottom">
      <p className="mb-0">&copy; {new Date().getFullYear()} Dahlhauser Solutions. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
