import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const ServiceCard = ({ service, isHighlighted }) => {
  return (
    <Link className="p-2" to={`/services/${service.id}`} style={{ textDecoration: 'none' }}>
      <Card
        id={service.id}
        className={`d-flex w-100 mx-auto my-4 overflow-hidden ${isHighlighted ? 'bg-warning' : ''}`}
        style={{ height: '250px', padding: '10px' }} // Added padding around the card
      >
        <Box className="w-33" style={{ flex: '1 1 33%' }}>
          <img
            src={service.image}
            alt={service.title}
            className="h-100 w-100 object-cover"
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        </Box>
        <CardContent className="w-67 d-flex flex-column justify-content-start p-3" style={{ flex: '1 1 67%' }}>
          <Typography gutterBottom variant="h6" component="div">
            {service.title}
          </Typography>
          <Box component="ul" sx={{ listStyleType: 'disc', paddingInlineStart: '20px', fontSize: '0.875rem' }}>
            {service.description.map((item, index) => (
              <Box component="li" key={index} sx={{ fontSize: '0.875rem' }}>
                {item}
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export default ServiceCard;
