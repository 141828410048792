// Services.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { servicesData } from '../data/ServicesData';
import ServiceCard from '../components/ServiceCard';

const Services = () => {
  const location = useLocation();
  const [highlightedId, setHighlightedId] = useState(null);

  useEffect(() => {
    const targetId = location.hash.replace("#", "");
    if (targetId) {
      setHighlightedId(targetId);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTimeout(() => {
          setHighlightedId(null);
        }, 3000);
      }
    }
  }, [location.hash]);

  return (
    <div className="container py-5" style={{ maxWidth: '1920px' }}>
      <Typography variant="h3" className="text-center mb-5 fw-bold">Our Services</Typography>
      <div className="row row-cols-1 row-cols-lg-2 g-4">
        {servicesData.map((service, index) => (
          <ServiceCard key={index} service={service} isHighlighted={highlightedId === service.id} />
        ))}
      </div>
    </div>
  );
};

export default Services;
