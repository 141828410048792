import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaQuoteLeft } from 'react-icons/fa'; // Import the quote icon
import { db } from '../firebaseConfig'; // Import your firebase configuration
import { collection, getDocs } from 'firebase/firestore';

const Testimonials = ({ newStyle = false }) => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      const testimonialsCollection = collection(db, 'testimonials');
      const testimonialsSnapshot = await getDocs(testimonialsCollection);
      const testimonialsList = testimonialsSnapshot.docs.map(doc => doc.data());
      setTestimonials(testimonialsList);
    };

    fetchTestimonials();
  }, []);

  // Adjust slider settings based on the number of testimonials
  const settings = {
    dots: true,
    infinite: testimonials.length > 2, // Disable infinite if 3 or fewer testimonials
    autoplay: testimonials.length > 2, // Disable autoplay if 3 or fewer testimonials
    autoplaySpeed: 2500,
    speed: 500,
    slidesToShow: testimonials.length > 3 ? 3 : testimonials.length, // Show only the number of testimonials available
    slidesToScroll: 1,
    // Add responsive settings if needed to adjust for smaller screens
    responsive: [
      {
        breakpoint: 1024, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: testimonials.length > 2 ? 2 : testimonials.length, // Show 2 or fewer slides based on the number of testimonials
        }
      },
      {
        breakpoint: 600, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1, // Show 1 slide on very small screens
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2500,
          speed: 500,
        }
      }
    ]
  };

  return (
    newStyle ? (
      <section className="service-testimonials">
        <h2 className="text-center">What Our Clients Say</h2>
        {testimonials.map((d) => (
          <blockquote key={d.name} className="blockquote text-center">
            <FaQuoteLeft className="quote-icon" /> {d.review}
            <footer className="blockquote-footer">
              - {d.name}, <cite title="Source Title">{d.title}</cite>
            </footer>
          </blockquote>
        ))}
      </section>
    ) : (
      <div className="container mt-5">
        <div className="text-center mb-5">
          <h2 className="text-center font-weight-bold">Testimonials</h2>
        </div>
        <Slider {...settings}>
          {testimonials.map((d) => (
            <div key={d.name} className="position-relative text-black overflow-hidden">
              <div className="d-flex justify-content-center align-items-center rounded-top">
                <img src={d.img} alt="" className="rounded-circle m-4" style={{ height: '176px', width: '176px' }} />
              </div>
              <div className="card-body d-flex flex-column align-items-center justify-content-center gap-3 p-4">
                <h5 className="card-title font-weight-bold">{d.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{d.title}</h6>
                <p className="card-text text-center">{d.review}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  );
};

export default Testimonials;
