// BlogCard.js

import React from 'react';
import { Link } from 'react-router-dom';

const customButtonStyle = {
  backgroundColor: '#007bff',
  color: '#fff',
  borderRadius: '20px',
  padding: '10px 20px',
  textDecoration: 'none',
  fontSize: '1rem',
  fontWeight: 'bold',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  transition: 'background-color 0.3s ease',
  display: 'inline-block'
};

const customButtonHoverStyle = {
  backgroundColor: '#0056b3',
};

const BlogCard = ({ blog }) => {
  const baseUrl = window.location.origin;
  const imageUrl = `${baseUrl}/images/${blog.imageName}`;

  return (
    <div className="card mb-4 p-2" style={{ border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
      <img src={imageUrl} className="card-img-top" alt={blog.title} style={{ borderRadius: '10px' }} />
      <div className="card-body">
        <h2 className="card-title" style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px' }}>{blog.title}</h2>
        <p className="card-text" style={{ fontSize: '1rem', color: '#555', marginBottom: '15px' }}>{blog.excerpt}</p>
        <Link to={`/blog/${blog.id}`} className="btn" style={customButtonStyle}
          onMouseEnter={e => e.target.style.backgroundColor = customButtonHoverStyle.backgroundColor}
          onMouseLeave={e => e.target.style.backgroundColor = customButtonStyle.backgroundColor}>
          Read More
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
