export const servicesData = [
  {
    id: 'azure-architecture',
    title: 'Azure Architecture and Management',
    description: [
      'Custom Azure infrastructure design and implementation',
      'Azure cost optimization and billing management',
      'Azure security and compliance assessment',
      'Azure backup and disaster recovery planning',
      'Azure application deployment and scaling',
    ],
    image: '/images/azure_solutions.webp',
  },
  {
    id: 'm365-management',
    title: 'M365 Management',
    description: [
      'M365 setup and migration services',
      'Collaboration tools setup and optimization (Teams, SharePoint)',
      'Email system management (Exchange Online)',
      'Security and compliance configurations',
      'Training for new technologies and features, including Microsoft Copilot',
    ],
    image: '/images/o365_collaboration.webp',
  },
  {
    id: 'security-management',
    title: 'Security Management',
    description: [
      'Monthly Secure Score review and improvement planning',
      'Implementation of security recommendations',
      'Incident detection and response services',
      'Endpoint protection and threat management',
      'Data protection and loss prevention strategies',
    ],
    image: '/images/cybersecurity_fortification.webp',
  },
  {
    id: 'server-desktop-management',
    title: 'Server/Desktop Monitoring and Management',
    description: [
      'Software installation, updates, and patch management',
      'Setup and Configuration',
      'Antivirus and malware protection',
      'Remote troubleshooting and support',
      'Hardware and software inventory management',
      'Performance monitoring and optimization',
      'Regular maintenance and updates',
    ],
    image: '/images/desktop_server_management.webp',
  },
  {
    id: 'web-mobile-app-services',
    title: 'Mobile App/Web Services',
    description: [
      'Custom mobile app development (iOS, Android)',
      'App maintenance and updates',
      'App store optimization and deployment',
      'Integration with business systems',
      'User experience and interface design',
      'Website design, development, and hosting',
      'SEO optimization and analytics setup'
    ],
    image: '/images/mobile_app_innovation.webp',
  },
  {
    id: 'bundled-managed-services',
    title: 'Bundled Managed Services',
    description: [
      'Comprehensive IT support package tailored to small businesses',
      'Combination of essential services like M365 management, desktop and server management, and security oversight',
      'Flexible add-ons for website and mobile app services',
      'Priority support and dedicated account management',
    ],
    image: '/images/bundled_services.webp',
  },
  {
    id: 'connectivity-services',
    title: 'Low Voltage & Cabling Solutions',
    description: [
      'Professional installation of CAT6 cabling to ensure robust network infrastructure',
      'Structured cabling solutions for improved network reliability and scalability',
      'Cabling certification and maintenance for optimal performance',
      'Customized cabling layouts to suit business needs and office spaces',
    ],
    image: '/images/connectivity_solutions.webp', // Placeholder image path
  },
  {
    id: 'wifi-av-integration',
    title: 'WiFi & Audio-Visual Integration',
    description: [
      'Advanced WiFi network setups for maximum coverage and speed',
      'Professional audio-visual system installations for conferencing and presentations',
      'Custom AV solutions to enhance communication and collaboration',
      'Ongoing support and optimization of WiFi and AV systems',
    ],
    image: '/images/wifi_av_integration.webp', // Placeholder image path
  }
];



