import React from 'react';
import './ServicePage.css';
import { FaCheckCircle, FaQuoteLeft } from 'react-icons/fa'; // Import icons
import CallToAction from '../sections/CallToAction';
import Testimonials from '../sections/Testimonials';

const WiFiAVIntegration = () => {
  return (
    <div className="service-page">
      <h1>WiFi & Audio-Visual Integration</h1>
      <img src="/images/wifi_av_integration.webp" alt="WiFi & Audio-Visual Integration" />

      <section className="service-intro">
        <h2>Introduction</h2>
        <p>At Dahlhauser Solutions, we offer advanced WiFi and Audio-Visual Integration services to enhance your business's communication and collaboration capabilities. Our expert team ensures maximum coverage and speed for your WiFi networks and professional installation of audio-visual systems tailored to your needs.</p>
      </section>

      <section className="service-details">
        <h2>Our Services</h2>
        <ul>
          <li><FaCheckCircle /> Advanced WiFi network setups for maximum coverage and speed</li>
          <li><FaCheckCircle /> Professional audio-visual system installations for conferencing and presentations</li>
          <li><FaCheckCircle /> Custom AV solutions to enhance communication and collaboration</li>
          <li><FaCheckCircle /> Ongoing support and optimization of WiFi and AV systems</li>
        </ul>
      </section>

      <section className="service-benefits">
        <h2>Benefits of Our WiFi & Audio-Visual Integration Services</h2>
        <ul>
          <li><FaCheckCircle /> <strong>Enhanced Connectivity:</strong> Ensure reliable and high-speed WiFi connectivity across your premises.</li>
          <li><FaCheckCircle /> <strong>Professional AV Installations:</strong> Benefit from expert installations of audio-visual systems for seamless presentations and conferencing.</li>
          <li><FaCheckCircle /> <strong>Custom Solutions:</strong> Receive tailored AV solutions designed to meet your specific business requirements.</li>
          <li><FaCheckCircle /> <strong>Ongoing Support:</strong> Maintain optimal performance with our continuous support and system optimization services.</li>
          <li><FaCheckCircle /> <strong>Improved Collaboration:</strong> Enhance communication and collaboration within your organization with state-of-the-art AV setups.</li>
        </ul>
      </section>

      <Testimonials newStyle={true} />

      <section className="service-cta">
        <CallToAction />
      </section>
    </div>
  );
};

export default WiFiAVIntegration;
