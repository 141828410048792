import React from 'react';
import './ServicePage.css';
import { FaCheckCircle, FaQuoteLeft } from 'react-icons/fa'; // Import icons
import CallToAction from '../sections/CallToAction';
import Testimonials from '../sections/Testimonials';

const M365Management = () => {
  return (
    <div className="service-page">
      <h1>M365 Management</h1>
      <img src="/images/o365_collaboration.webp" alt="M365 Management" />

      <section className="service-intro">
        <h2>Introduction</h2>
        <p>At Dahlhauser Solutions, our M365 Management services are designed to help businesses optimize their use of Microsoft 365 tools, ensuring seamless collaboration, enhanced productivity, and robust security. Our team of experts offers comprehensive support, from setup and migration to ongoing management and training.</p>
      </section>

      <section className="service-details">
        <h2>Our Services</h2>
        <ul>
          <li><FaCheckCircle /> M365 setup and migration services</li>
          <li><FaCheckCircle /> Collaboration tools setup and optimization (Teams, SharePoint)</li>
          <li><FaCheckCircle /> Email system management (Exchange Online)</li>
          <li><FaCheckCircle /> Security and compliance configurations</li>
          <li><FaCheckCircle /> Training for new technologies and features, including Microsoft Copilot</li>
        </ul>
      </section>

      <section className="service-benefits">
        <h2>Benefits of Our M365 Management Services</h2>
        <ul>
          <li><FaCheckCircle /> <strong>Seamless Migration:</strong> Experience a smooth transition to M365 with minimal disruption to your operations.</li>
          <li><FaCheckCircle /> <strong>Enhanced Collaboration:</strong> Leverage tools like Teams and SharePoint to boost teamwork and communication.</li>
          <li><FaCheckCircle /> <strong>Improved Security:</strong> Protect your data with advanced security and compliance configurations.</li>
          <li><FaCheckCircle /> <strong>Increased Productivity:</strong> Maximize the potential of M365 with expert setup and ongoing support.</li>
          <li><FaCheckCircle /> <strong>Comprehensive Training:</strong> Ensure your team is up-to-date with the latest features and technologies, including Microsoft Copilot.</li>
        </ul>
      </section>

      <Testimonials newStyle={true} />

      <section className="service-cta">
        <CallToAction />
      </section>
    </div>
  );
};

export default M365Management;
