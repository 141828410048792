// AboutUs.js
import React from 'react';
import AboutOverview from '../sections/AboutOverview';

const AboutUs= () => {
  return (
    <div>
      <AboutOverview />
    </div>
  );
};

export default AboutUs;