import React from 'react';
import HeroSection from '../sections/HeroSection'; // Adjust the path as necessary
import AboutOverview from '../sections/AboutOverview';
import ServicesTeaser from '../sections/ServicesTeaser';
import Testimonials from '../sections/Testimonials';
import CallToAction from '../sections/CallToAction';

const Home = () => {
  return (
    <div style={{ marginBottom: '30px' }}> {/* Adjust the value as needed */}
      <AboutOverview />
      <HeroSection /> {/* This line adds the HeroSection to the Home page */}
      <ServicesTeaser />
      <Testimonials />
      <CallToAction />
    </div>
  );
};

export default Home;

