import React from 'react';
import './ServicePage.css';
import { FaCheckCircle, FaQuoteLeft } from 'react-icons/fa'; // Import icons
import CallToAction from '../sections/CallToAction';
import Testimonials from '../sections/Testimonials';

const AzureArchitecture = () => {
  return (
    <div className="service-page">
      <h1>Azure Architecture and Management</h1>
      <img src="/images/azure_solutions.webp" alt="Azure Architecture and Management" />

      <section className="service-intro">
        <h2>Introduction</h2>
        <p>At Dahlhauser Solutions, we specialize in providing comprehensive Azure Managed Services designed to optimize your cloud infrastructure, ensure compliance, and enhance security. Our team of certified Azure experts delivers tailored solutions to meet the unique needs of your business.</p>
      </section>

      <section className="service-details">
        <h2>Our Services</h2>
        <ul>
          <li><FaCheckCircle /> Custom Azure infrastructure design and implementation</li>
          <li><FaCheckCircle /> Azure cost optimization and billing management</li>
          <li><FaCheckCircle /> Azure security and compliance assessment</li>
          <li><FaCheckCircle /> Azure backup and disaster recovery planning</li>
          <li><FaCheckCircle /> Azure application deployment and scaling</li>
        </ul>
      </section>

      <section className="service-benefits">
        <h2>Benefits of Our Azure Managed Services</h2>
        <ul>
          <li><FaCheckCircle /> <strong>Cost Efficiency:</strong> Reduce operational costs with optimized Azure spending and efficient resource management.</li>
          <li><FaCheckCircle /> <strong>Enhanced Security:</strong> Protect your data and applications with robust security measures and compliance assessments.</li>
          <li><FaCheckCircle /> <strong>Scalability:</strong> Easily scale your applications and infrastructure to meet changing business demands.</li>
          <li><FaCheckCircle /> <strong>Disaster Recovery:</strong> Ensure business continuity with reliable backup and disaster recovery solutions.</li>
          <li><FaCheckCircle /> <strong>Expert Support:</strong> Gain access to our team of certified Azure professionals for ongoing support and management.</li>
        </ul>
      </section>

      <Testimonials newStyle={true} />

      <section className="service-cta">
        <CallToAction />
      </section>
    </div>
  );
};

export default AzureArchitecture;
