import React from 'react';
import './ServicePage.css';
import { FaCheckCircle, FaQuoteLeft } from 'react-icons/fa'; // Import icons
import CallToAction from '../sections/CallToAction';
import Testimonials from '../sections/Testimonials';

const BundledManagedServices = () => {
  return (
    <div className="service-page">
      <h1>Bundled Managed Services</h1>
      <img src="/images/bundled_services.webp" alt="Bundled Managed Services" />

      <section className="service-intro">
        <h2>Introduction</h2>
        <p>At Dahlhauser Solutions, we offer comprehensive Bundled Managed Services tailored to meet the unique needs of small businesses. Our bundled packages combine essential IT services to provide you with a one-stop solution for all your IT support, security, and management needs.</p>
      </section>

      <section className="service-details">
        <h2>Our Services</h2>
        <ul>
          <li><FaCheckCircle /> Comprehensive IT support package tailored to small businesses</li>
          <li><FaCheckCircle /> Combination of essential services like O365 management, desktop and server management, and security oversight</li>
          <li><FaCheckCircle /> Flexible add-ons for website and mobile app services</li>
          <li><FaCheckCircle /> Priority support and dedicated account management</li>
        </ul>
      </section>

      <section className="service-benefits">
        <h2>Benefits of Our Bundled Managed Services</h2>
        <ul>
          <li><FaCheckCircle /> <strong>Cost-Effective:</strong> Save money with our bundled service packages that offer a range of essential IT services at a competitive price.</li>
          <li><FaCheckCircle /> <strong>Streamlined Management:</strong> Simplify your IT management with a single point of contact for all your IT needs.</li>
          <li><FaCheckCircle /> <strong>Enhanced Security:</strong> Benefit from comprehensive security oversight to protect your business data and systems.</li>
          <li><FaCheckCircle /> <strong>Scalable Solutions:</strong> Easily add or remove services as your business needs change.</li>
          <li><FaCheckCircle /> <strong>Dedicated Support:</strong> Enjoy priority support and personalized service with a dedicated account manager.</li>
        </ul>
      </section>

      <Testimonials newStyle={true} />

      <section className="service-cta">
        <CallToAction />
      </section>
    </div>
  );
};

export default BundledManagedServices;
