import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import AzureArchitecture from './pages/AzureArchitecture';
import M365Management from './pages/M365Management';
import SecurityManagement from './pages/SecurityManagement';
import ServerDesktopManagement from './pages/ServerDesktopManagement';
import MobileAppWebServices from './pages/MobileAppWebServices';
import BundledManagedServices from './pages/BundledManagedServices';
import LowVoltageCabling from './pages/LowVoltageCabling';
import WiFiAVIntegration from './pages/WiFiAVIntegration';
import BlogList from './components/BlogList';
import BlogDetails from './components/BlogDetails';
import ScrollToTop from './components/ScrollToTop';
import './App.css';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import theme from './theme'; // Import the theme object
import { Helmet } from 'react-helmet';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.fonts.primary};
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    // Other global styles
  }
`;

const App = () => {
  return (
    <ThemeProvider theme={theme}> {/* Provide the theme to all components */}
      <GlobalStyle />
      <Router>
        <Helmet>
          <title>Charlotte | IT Support Solutions | Dahlhauser Solutions</title>
          <meta name="description" content="Dahlhauser Solutions provides top-notch managed IT services in the Charlotte Metro Area. Our services include IT support, network security, and more. Contact us today!" />
        </Helmet>
        <Header />
        <ScrollToTop /> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="services/azure-architecture" element={<AzureArchitecture />} />
          <Route path="services/m365-management" element={<M365Management />} />
          <Route path="services/security-management" element={<SecurityManagement />} />
          <Route path="services/server-desktop-management" element={<ServerDesktopManagement />} />
          <Route path="services/web-mobile-app-services" element={<MobileAppWebServices />} />
          <Route path="services/bundled-managed-services" element={<BundledManagedServices />} />
          <Route path="services/connectivity-services" element={<LowVoltageCabling />} />
          <Route path="services/wifi-av-integration" element={<WiFiAVIntegration />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="blog" element={<BlogList />} />
          <Route path="blog/:id" element={<BlogDetails />} />
          {/* Add additional routes here */}
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
