import React from 'react';
import './ServicePage.css';
import { FaCheckCircle, FaQuoteLeft } from 'react-icons/fa'; // Import icons
import CallToAction from '../sections/CallToAction';
import Testimonials from '../sections/Testimonials';

const SecurityManagement = () => {
  return (
    <div className="service-page">
      <h1>Security Management</h1>
      <img src="/images/cybersecurity_fortification.webp" alt="Security Management" />

      <section className="service-intro">
        <h2>Introduction</h2>
        <p>At Dahlhauser Solutions, our Security Management services are designed to protect your business from cyber threats and ensure data integrity. Our proactive approach includes continuous monitoring, incident detection, and comprehensive security measures tailored to your specific needs.</p>
      </section>

      <section className="service-details">
        <h2>Our Services</h2>
        <ul>
          <li><FaCheckCircle /> Monthly Secure Score review and improvement planning</li>
          <li><FaCheckCircle /> Implementation of security recommendations</li>
          <li><FaCheckCircle /> Incident detection and response services</li>
          <li><FaCheckCircle /> Endpoint protection and threat management</li>
          <li><FaCheckCircle /> Data protection and loss prevention strategies</li>
        </ul>
      </section>

      <section className="service-benefits">
        <h2>Benefits of Our Security Management Services</h2>
        <ul>
          <li><FaCheckCircle /> <strong>Proactive Threat Detection:</strong> Identify and mitigate threats before they impact your business.</li>
          <li><FaCheckCircle /> <strong>Enhanced Data Protection:</strong> Safeguard sensitive information with robust data protection strategies.</li>
          <li><FaCheckCircle /> <strong>Improved Compliance:</strong> Ensure your business meets industry standards and regulatory requirements.</li>
          <li><FaCheckCircle /> <strong>Expert Incident Response:</strong> Receive immediate support and resolution in the event of a security incident.</li>
          <li><FaCheckCircle /> <strong>Comprehensive Endpoint Security:</strong> Protect all devices within your network from potential threats.</li>
        </ul>
      </section>

      <Testimonials newStyle={true} />

      <section className="service-cta">
        <CallToAction />
      </section>
    </div>
  );
};

export default SecurityManagement;
