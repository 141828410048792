import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const HeroSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 2500,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true, // Centers the slides and shows partials on the sides
    centerPadding: '0px', // Adds padding on the sides of each slide
    responsive: [
      {
        breakpoint: 1024, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 2, // Show fewer slides on smaller screens
        }
      },
      {
        breakpoint: 600, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1, // Show 1 slide on very small screens
        }
      }
    ]
  };

  const data = [
    {
      title: "Empowering Your Business with AI",
      img: "/images/empowering_business.webp",
      description: "Unlock the full potential of your IT infrastructure and embrace AI to propel your business forward.",
      button: "Discover",
      linkid: "ai-services"
    },
    {
      title: "Custom Azure Solutions",
      img: "/images/azure_solutions.webp",
      description: "Tailor-made Azure infrastructures for efficiency, scalability, and cost-effectiveness.",
      button: "Learn",
      linkid: "azure-architecture"
    },
    {
      title: "M365 Collaboration",
      img: "/images/o365_collaboration.webp",
      description: "Seamless communication and collaboration solutions to unite your team, anywhere.",
      button: "Get Started",
      linkid: "m365-management"
    },
    {
      title: "Cybersecurity Fortification",
      img: "/images/cybersecurity_fortification.webp",
      description: "Advanced security measures to protect, detect, and respond to evolving cyber threats.",
      button: "Secure",
      linkid: "security-management"
    },
    {
      title: "Mobile App Innovation",
      img: "/images/mobile_app_innovation.webp",
      description: "Bespoke mobile applications to engage your customers and streamline operations.",
      button: "Explore",
      linkid: "web-mobile-app-services"
    },
    // Adding new item for Low Voltage, WiFi, and AV Services
    {
      title: "Integrated Connectivity Solutions",
      img: "/images/connectivity_solutions.webp", // Replace with actual path to the image generated for item 6
      description: "Comprehensive low voltage, WiFi, and AV services to create a connected and efficient environment.",
      button: "Integrate",
      linkid: "connectivity-services"
    }
  ];

  return (
    <div className='container mt-5' style={{ maxWidth: '80%' }}>
      <div className="slider-container">
        <Slider {...settings}>
          {data.map((d) => (
            <div key={d.title} className="position-relative bg-white text-black overflow-hidden" style={{ height: '450px' }}>
              {/* Background image */}
              <img src={d.img} alt={d.title} className="w-100" style={{ height: '450px', objectFit: 'cover', borderRadius: '20px' }} />

              {/* Content */}
              <div className="position-absolute bottom-0 w-100 p-3 d-flex justify-content-center" style={{ bottom: 0 }}>
                <div className="p-3 text-white text-center mb-1" style={{ background: 'rgba(0, 0, 0, 0.6)', borderRadius: '10px' }} >
                  <p className="h5 font-weight-bold">{d.title}</p>
                  <p className="text-center">{d.description}</p>
                  <Link
                    to={`/services#${d.linkid}`}
                    className="mt-3 btn btn-primary btn-lg d-block text-center"
                  >
                    {d.button}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default HeroSection;
