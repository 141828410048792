// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCgWPxXk6FMuEsEvgUYiUGqsDYT4V9u2Dc",
  authDomain: "dahlhauser-net.firebaseapp.com",
  projectId: "dahlhauser-net",
  storageBucket: "dahlhauser-net.appspot.com",
  messagingSenderId: "571955598326",
  appId: "1:571955598326:web:063140b00677142efe2dd7",
  measurementId: "G-D5H19PGCTK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db, app };
