import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import backgroundImage from './assets/headerbanner.png'; // Adjust the path to where your image is located

const Header = () => {
  const [isSidenavVisible, setSidenavVisible] = useState(false);

  const toggleSidenav = () => setSidenavVisible(!isSidenavVisible);

  return (
    <header
      className="d-flex justify-content-between align-items-center bg-dark text-white py-2 px-4"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        height: '90px',
        backgroundSize: 'cover',
        backgroundPosition: 'left',
      }}
    >
      <div className="d-flex align-items-center">
        <img src="/images/logo_300.png" alt="Logo" style={{ height: '80px' }} />
        <div style={{ marginLeft: '10px' }}>
          <h1 className="h5 m-0 font-weight-bold">Dahlhauser</h1>
          <h1 className="h5 m-0 font-weight-bold ml-4">Solutions</h1>
        </div>
      </div>
      <div className="d-md-none" onClick={toggleSidenav} style={{ cursor: 'pointer' }}>
        <svg
          className="bi bi-list"
          width="1em"
          height="1em"
          fill="currentColor"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" d="M2.5 12.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zM2.5 8.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zM2.5 4.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11z" />
        </svg> {/* Hamburger Icon */}
      </div>
      <nav className="d-none d-md-flex ml-auto">
        <NavLink to="/" exact className="text-white mx-2 py-2" activeClassName="border-bottom border-white">Home</NavLink>
        <NavLink to="/services" exact className="text-white mx-2 py-2" activeClassName="border-bottom border-white">Services</NavLink>
        <NavLink to="/about-us" className="text-white mx-2 py-2" activeClassName="border-bottom border-white">About Us</NavLink>
        <NavLink to="/contact-us" className="text-white mx-2 py-2" activeClassName="border-bottom border-white">Contact Us</NavLink>
        <NavLink to="/blog" className="text-white mx-2 py-2" activeClassName="border-bottom border-white">IT Blog</NavLink>
      </nav>
      {isSidenavVisible && (
        <div className="position-fixed w-100 h-100 top-0 start-0 bg-dark bg-opacity-50" onClick={toggleSidenav} style={{ zIndex: 1050 }}>
          <div className="position-fixed h-100 bg-dark text-white p-4" style={{ width: '250px' }} onClick={(e) => e.stopPropagation()}>
            <button onClick={toggleSidenav} className="btn btn-link text-white p-0 mb-4">
              <svg
                className="bi bi-x"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fillRule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg> {/* Close Icon */}
            </button>
            <NavLink to="/" exact className="text-white d-block py-2" activeClassName="bg-secondary">Home</NavLink>
            <NavLink to="/services" exact className="text-white d-block py-2" activeClassName="bg-secondary">Services</NavLink>
            <NavLink to="/about-us" className="text-white d-block py-2" activeClassName="bg-secondary">About Us</NavLink>
            <NavLink to="/contact-us" className="text-white d-block py-2" activeClassName="bg-secondary">Contact Us</NavLink>
            <NavLink to="/blog" className="text-white d-block py-2" activeClassName="bg-secondary">IT Blog</NavLink>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
