import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';

const BlogDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [tocExpanded, setTocExpanded] = useState(false);

  const toggleToc = () => {
    setTocExpanded(!tocExpanded);
  };

  useEffect(() => {
    const fetchBlog = async () => {
      const docRef = doc(db, 'blog', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setBlog(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };
    fetchBlog();
  }, [id]);

  if (!blog) return <div>Loading...</div>;

  const baseUrl = window.location.origin;
  const imageUrl = `${baseUrl}/images/${blog.imageName}`;

  return (
    <div className="container mt-4">
      <div className="text-center mb-4">
        <img src={imageUrl} alt="Blog Banner" className="img-fluid" style={{ width: '100%', objectFit: 'cover', borderRadius: '10px' }} />
        <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginTop: '20px' }}>{blog.title}</h1>
      </div>
      <div className="card mb-4">
        <div className="card-header" onClick={toggleToc} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2 className="mb-0" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            Table of Contents
          </h2>
          {tocExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
        <div id="toc" className={`collapse ${tocExpanded ? 'show' : ''}`}>
          <div className="card-body">
            <ul style={{ paddingLeft: '20px', color: '#333' }}>
              {blog.content.sections.map((section, index) => (
                <li key={index} style={{ marginBottom: '10px' }}>
                  <a href={`#section-${index}`} style={{ textDecoration: 'none', color: '#007bff' }}>
                    {section.heading}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {blog.content.sections.map((section, index) => (
        <div key={index} id={`section-${index}`} className="card mb-4" style={{ border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
          <div className="card-body" style={{ padding: '30px' }}>
            <h2 style={{ fontSize: '1.75rem', fontWeight: '600', marginBottom: '15px' }}>{section.heading}</h2>
            {section.paragraphs.map((paragraph, idx) => (
              <div key={idx} style={{ fontSize: '1rem', lineHeight: '1.6', color: '#555' }} dangerouslySetInnerHTML={{ __html: paragraph }} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogDetails;
