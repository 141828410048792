import React from 'react';
import './ServicePage.css';
import { FaCheckCircle, FaQuoteLeft } from 'react-icons/fa'; // Import icons
import CallToAction from '../sections/CallToAction';
import Testimonials from '../sections/Testimonials';

const LowVoltageCabling = () => {
  return (
    <div className="service-page">
      <h1>Low Voltage & Cabling Solutions</h1>
      <img src="/images/connectivity_solutions.webp" alt="Low Voltage & Cabling Solutions" />

      <section className="service-intro">
        <h2>Introduction</h2>
        <p>At Dahlhauser Solutions, we provide professional Low Voltage and Cabling Solutions to ensure robust and reliable network infrastructure. Our team of experts offers comprehensive cabling services tailored to meet the specific needs of your business, enhancing connectivity and performance.</p>
      </section>

      <section className="service-details">
        <h2>Our Services</h2>
        <ul>
          <li><FaCheckCircle /> Professional installation of CAT6 cabling to ensure robust network infrastructure</li>
          <li><FaCheckCircle /> Structured cabling solutions for improved network reliability and scalability</li>
          <li><FaCheckCircle /> Cabling certification and maintenance for optimal performance</li>
          <li><FaCheckCircle /> Customized cabling layouts to suit business needs and office spaces</li>
        </ul>
      </section>

      <section className="service-benefits">
        <h2>Benefits of Our Low Voltage & Cabling Solutions</h2>
        <ul>
          <li><FaCheckCircle /> <strong>Improved Connectivity:</strong> Ensure reliable and high-speed network connectivity with professional cabling installation.</li>
          <li><FaCheckCircle /> <strong>Scalable Infrastructure:</strong> Design and implement cabling solutions that can grow with your business.</li>
          <li><FaCheckCircle /> <strong>Certified Quality:</strong> Benefit from certified cabling installations that meet industry standards.</li>
          <li><FaCheckCircle /> <strong>Custom Solutions:</strong> Get tailored cabling layouts that fit your unique business requirements.</li>
          <li><FaCheckCircle /> <strong>Ongoing Maintenance:</strong> Ensure optimal performance with regular cabling maintenance and support.</li>
        </ul>
      </section>

      <Testimonials newStyle={true} />

      <section className="service-cta">
        <CallToAction />
      </section>
    </div>
  );
};

export default LowVoltageCabling;
